.SessionsTable .rating-star {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .7em;
  margin-right: .7em;
  margin-bottom: 1.2em;
  border-right: .3em solid transparent;
  border-bottom: .7em solid #000000;
  border-left: .3em solid transparent;
  font-size: 8px;
}

.SessionsTable .rating-star:before,
.SessionsTable .rating-star:after {
  content: '';

  display: block;
  width: 0;
  height: 0;

  position: absolute;
  top: .6em;
  left: -1em;

  border-right: 1em solid transparent;
  border-bottom: .7em solid #000000;
  border-left: 1em solid transparent;

  transform: rotate(-35deg);
}

.SessionsTable .rating-star:after {
  transform: rotate(35deg);
}

.SessionsTable thead th .heading-container {
  display: flex;
}

.SessionsTable-icon_notes {
  display: inline-block;
  height: 1.4rem;
  margin-right: 0.8rem;
  width: 1.4rem;
}
