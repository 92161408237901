.search-filter-button-group {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.search-filter {
  margin-top: 1.6rem;
}

.search-filter:first-child {
  margin-top: 0;
}

.dropdown-filter {
  flex: 1;
}

.datepicker-filter {
  flex: 2;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .search-filter-button-group {
    flex-direction: row;
  }

  .search-filter {
    margin-right: .5em;
    margin-top: 0;
  }
}
