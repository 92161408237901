.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
}

.modal-overlay {
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  overflow-y: auto  ;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.modal-overlay_dark {
  background: #161616;
}

.modal {
  padding: 4rem 0;
}

.modal-content-top {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow-y: auto;
  padding: 4.8rem 1.6rem;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.modal-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow-y: auto;
  padding: 4.8rem 1.6rem;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.modal-close {
  fill: #161616;
  stroke: #161616;
  height: 2rem;
  position: absolute;
  right: 2.4rem;
  top: 2.4rem;
  width: 2rem;
}

.modal-overlay_dark .modal-close {
  fill: #00a1e1;
  stroke: #00a1e1;
}

.modal-body {
  font-size: 1.1em;
  font-weight: 400;
  padding: 2em;
  text-align: center;
}

.modal-footer {
  text-align: center;
}

.modal-link {
  color: #00a1e1;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .modal-content {
    justify-content: center;
    max-width: 768px;
    padding: 4.8rem 1.6rem;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .modal {
    display: flex;
    height: 100%;
  }

  .modal-content {
    flex-direction: row;
    max-width: 128rem;
    padding: 4.8rem 6.4rem;
  }
}
