.SessionsSearchFiltersGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 0;
  margin-top: 1.6rem;
  overflow: hidden;
}

.SessionsSearchFiltersGroup .heading {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 1.1em;
  font-weight: 600;
}

.SessionsSearchFiltersGroup .filter-group-container {
  margin-bottom: 4rem;
  width: 100%;
}

.heading .filter-heading {
  display: none;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .SessionsSearchFiltersGroup .filter-group-container {
    width: 33%;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .SessionsSearchFiltersGroup {
    flex-direction: column;
    height: auto;
    margin-top: 0;
    overflow: unset;
  }

  .SessionsSearchFiltersGroup .filter-group-container {
    margin-bottom: 0;
    margin-top: 25%;
    width: auto;
  }

  .heading .filter-controls {
    display: none;
  }

  .heading .filter-heading {
    display: block;
  }
}
