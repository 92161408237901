/* ----------------------
 TEXT STYLES
------------------------- */

.txt {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
}

.txt_sm {
  font-size: 1.4rem;
}

.txt_md {
  font-size: 1.8rem;
}

.text_white {
  color: #ffffff;
}

.text_loading_message {
  margin-top: 2em;
}

.txt_mdLg {
  font-size: 2.4rem;
}

.txt_lg {
  font-size: 3.2rem;
}

.txt_lgMobile {
  font-size: 3.2rem;
}

.txt_xl,
.txt_huge {
  font-size: 4.8rem;
}

.txt_giant {
  font-size: 10.4rem;
}

.txt_lgPlus {
  font-size: 3.6rem;
}

.txt_lgMobile {
  font-size: 4.8rem;
}

.txt_huge {
  font-size: 7.2rem;
}

.txt_link {
  color: #00a1e1;
  text-decoration: none;
}

/* ----------------------
 TEXT WEIGHT
------------------------- */

.txt_light {
  font-weight: 300;
}

.txt_normal {
  font-weight: 400;
}

.txt_medium {
  font-weight: 500;
}

.txt_bold {
  font-weight: 600;
}

/* ----------------------
 TEXT COLORS
------------------------- */

.text_colorDarkGray {
  color: #9d9d9d;
}

.mix-txt_colorDefaultBlue {
  color: #00a1e1;
}

.mix-txt_colorLightest {
  color: #ffffff;
}

.mix-txt_colorDefaultGray {
  color: #9d9d9d;
}

.mix-txt_colorRegularGray {
  color: #8b8b8b;
}

.mix-txt_colorDarkGray {
  color: #666666;
}

.mix-txt_colorDarkest {
  color: #161616;
}

.mix-txt_colorDefaultOrange {
  color: #fb541a;
}

/* ----------------------
 TEXT TRANSFORMS
------------------------- */

.mix-txt_capitalize {
  text-transform: capitalize;
}

.mix-txt_lowercase {
  text-transform: lowercase;
}

.mix-txt_uppercase {
  letter-spacing: 0.09rem;
  text-transform: uppercase;
}

.mix-txt_uppercaseLg {
  letter-spacing: 0.13rem;
  text-transform: uppercase;
}

.mix-txt_squeeze {
  letter-spacing: -0.2rem;
}

.mix-txt_underline {
  text-decoration: underline;
}

.mix-txt_wrap {
  word-wrap: break-word;
}

/* ----------------------
 TEXT ALIGN
------------------------- */

.mix-txt_leftAlign {
  text-align: left;
}

.mix-txt_centerAlign {
  text-align: center;
}

.mix-txt_rightAlign {
  text-align: right;
}

/* ----------------------
 TEXT HEIGHT
------------------------- */

.mix-txt_flatHeight {
  line-height: 1;
}

.mix-txt_mediumHeight {
  line-height: 1.33;
}

.mix-txt_largeHeight {
  line-height: 1.5;
}

/* ----------------------
 TEXT HORIZONTAL SPACING
------------------------- */

.mix-txt_divider {
  display: inline-block;
  margin: 0 rhythm(1);
}
