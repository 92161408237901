.Button {
    background: transparent;
    border: 0.1rem solid #00a1e1;
    color: #00a1e1;
    cursor: pointer;
    display: inline-block;
    font-weight: 300;
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
    line-height: 1.2;
    height: auto;
    outline: none;
    padding: 1.8rem 2.4rem 1.5rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    min-width: 150px;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.Button:hover,
.Button:focus {
    background: #00a1e1;
    color: #ffffff;
}

.Button:disabled {
    border: 0.1rem solid #666;
    color: #666;
    background: transparent;
}

.Button_light {
    color: #161616;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
    .Button {
        width: auto;
    }

    .Button_md-wide {
        padding: 2rem 9.4rem 1.8rem;
    }
}

.Intro .header {
  line-height: 1.1;
  text-align: center;
  display: block;
  font-weight: 400;
  font-size: 4.8rem;
  color: white;
}

.Intro .summary {
  line-height: 1.33;
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  color: #9D9D9D;
  max-width: 34rem;
  margin: 4rem auto 0;
}

.Intro .summary_wide {
  max-width: 48rem;
}

@media only screen and (min-width: 768px) {
  .Intro .header {
    font-size: 7.2rem;
  }
}

.loader {
  background: #00b6ff;
  height: 0.5rem;
  position: absolute;
  right: 100%;
  top: 0;
  width: 100vw;
  z-index: 1;
}

.loader_fixed {
  position: fixed;
}

.Main {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.Main .top {
  background-image: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAA8AAD/4QOPaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjMtYzAxMSA2Ni4xNDU2NjEsIDIwMTIvMDIvMDYtMTQ6NTY6MjcgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bXBNTTpPcmlnaW5hbERvY3VtZW50SUQ9InhtcC5kaWQ6YTk0ZTg1OWEtYzI1Yy00MTRiLTllY2YtNGZhNzIyMDVjZGI5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkYyNjg1MTdFMjZEMjExRTdBODhCRDNEMzE3Mzk4NUI3IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkYyNjg1MTdEMjZEMjExRTdBODhCRDNEMzE3Mzk4NUI3IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDQyAyMDE3IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ZjE4MWYzNGUtZjI3Mi00MWFkLWE5YWItMmI2ZDYyNDUzYmNkIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6NjJhYTIxZDItNWVhZC0xMTdhLTg4MWItYjNlNDYyY2I4OTUyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+/+4ADkFkb2JlAGTAAAAAAf/bAIQABgQEBAUEBgUFBgkGBQYJCwgGBggLDAoKCwoKDBAMDAwMDAwQDA4PEA8ODBMTFBQTExwbGxscHx8fHx8fHx8fHwEHBwcNDA0YEBAYGhURFRofHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8f/8AAEQgABAAEAwERAAIRAQMRAf/EAE8AAQAAAAAAAAAAAAAAAAAAAAgBAQAAAAAAAAAAAAAAAAAAAAAQAAEFAAAAAAAAAAAAAAAAAADwQYEyQhEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8APLYopA//2Q==);
  background-position: 0 0;
  background-repeat: repeat;
  padding: 14rem 0 12rem 0;
}

.Main .top_slim {
  padding: 12rem 0 4rem;
}

.Main .top_alignTop {
  align-items: flex-start;
}

.Main .top_fillScreen {
  flex-grow: 1;
}

.Main .bottom {
  background: #ffffff;
  flex-grow: 2;
  padding: 8rem 0;
}

.Main .bottom_dark {
  background: #161616;
}

.Main .bottom_extraPad {
  padding: 6.4rem  0;
}

.Landing {
  text-align: center;
}

.cta-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2em;
}

.Sessions a.export-list-link {
  display: block;
  font-weight: 400;
  font-weight: bold;
  color: #449FDB;
  text-decoration: none;
  text-transform: uppercase;
}

.sessions-table-header {
  margin-bottom: 3.2rem;
}

.Sessions .export-list-link-container {
  margin-top: 1.6rem;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .Sessions .export-list-link-container {
    margin-top: 0;
  }

  .Sessions .sessions-table-footer {
    display: flex;
    justify-content: space-between;
    margin-top: .55em;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .sessions-table-container {
    padding-left: 2.4rem;
  }
}

.SessionsTable .rating-star {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .7em;
  margin-right: .7em;
  margin-bottom: 1.2em;
  border-right: .3em solid transparent;
  border-bottom: .7em solid #000000;
  border-left: .3em solid transparent;
  font-size: 8px;
}

.SessionsTable .rating-star:before,
.SessionsTable .rating-star:after {
  content: '';

  display: block;
  width: 0;
  height: 0;

  position: absolute;
  top: .6em;
  left: -1em;

  border-right: 1em solid transparent;
  border-bottom: .7em solid #000000;
  border-left: 1em solid transparent;

  -webkit-transform: rotate(-35deg);

          transform: rotate(-35deg);
}

.SessionsTable .rating-star:after {
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
}

.SessionsTable thead th .heading-container {
  display: flex;
}

.SessionsTable-icon_notes {
  display: inline-block;
  height: 1.4rem;
  margin-right: 0.8rem;
  width: 1.4rem;
}

.AddTransactionForm-container {
  width: 100%;
}

.AddTransactionForm-container .form-heading {
  color: #161616;
  font-weight: 300;
  font-size: 2.75rem;
  margin: 0 auto 7rem;
  text-transform: uppercase;
}

.ProgressBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  letter-spacing: .1rem;
  margin: 5rem auto;
  width: 100%;
}

.parent-label {
  margin: 1rem 0;
}

.parent-label button {
  text-align: left;
}

.ProgressBarStepLabel {
  color: #9D9D9D;
  font-weight: 300;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.ProgressBarStepLabel_active {
  color: #161616;
  font-weight: 400;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.ProgressBarStepLabel_default {
  color: #161616;
  font-weight: 300;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.ProgressBarStepLabel_previous {
  color: #9D9D9D;
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.subtitle {
  display: none;
}

.subtitle_previous {
  display: block;
  color: #00a1e1;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: .1rem;
  padding-top: 1rem;
  width: 100%;
}

.AddTransactionForm {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5.6rem;
}

.AddTransactionForm .form-step {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.AddTransactionForm .form-step .Button {
  color: #161616;
  font-size: 1.7rem;
  margin: 0 0 2rem;
}

.confirm-transaction {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 5rem 0;
}

.confirm-transaction_summary {
  align-items: flex-end;
  display: flex;
  font-size: 1.8rem;
  flex-direction: column;
  margin: 0 0 15rem;
}

.confirm-transaction_right {
  display: flex;
  flex-direction: column;
}

.confirm-transaction_left {
  display: flex;
  flex-direction: column;
}

.confirm-transaction_labels {
  color: #9D9D9D;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  justify-content: space-around;
  letter-spacing: .1rem;
  text-transform: uppercase;
  width: 80%;
}

.confirm-transaction_labels li {
  display: flex;
  flex-direction: column;
}

.confirm-transaction_labels .subtitles {
  color: #161616;
  font-size: 2.5rem;
  font-weight: 300;
  margin: 1.2rem 0;
}

.confirm-transaction .confirm-transaction_right .Button {
  width: 100%;
}

.confirm-transaction_email {
  font-weight: 800;
}

.transaction-completed {
  font-size: 2.5rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
  width: 100%;
}

.transaction-completed_confirmation {
  display: flex;
}

.transaction-completed_txt {
  margin-top: 2.5rem;
}

.transaction-completed_icon {
  background-image: url(/static/media/icons-check-mark.0b18ae2e.svg);
  height: 7rem;
  background-repeat: no-repeat;
  width: 9rem;
}

.transaction-completed_add-another {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5rem 0;
}

.transaction-completed .transaction-completed_add-another .Button {
  margin: 8rem 0 4rem;
}

.back-to-session_link {
  color: #00a1e1;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
}

.modal-content {
  align-items: baseline !important;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .AddTransactionForm-container {
    display: flex;
    flex-direction: column;
  }

  .ProgressBar {
    flex-direction: row;
  }

  .parent-label {
    margin: 0 1rem;
    text-align: center;
    white-space: normal;
    width: 12rem;
  }

  .parent-label button {
    text-align: center;
  }

  .AddTransactionForm .form-step .Button {
    width: 30%;
  }

  .confirm-transaction {
    width: 90%;
  }

  .confirm-transaction_left {
    width: 65%;
  }
}

.NavigationDropDownIcon {
  display: inline-block;
  margin-left: auto;
  transition: all .5s cubic-bezier(.25, .46, .45, .94);
  -webkit-transform-origin: 50% 43%;
          transform-origin: 50% 43%;
  -webkit-transform: rotate(-180deg) translateZ(0);
          transform: rotate(-180deg) translateZ(0);
}

.NavigationDropDownIcon.open {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.NavigationDropDownIcon i {
  fill: #00a1e1;
  height: 1.4rem;
  width: 1.4rem;
  display: inline-block;
  position: relative;
}

.NavigationDropDownIcon i > * {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.dropdown {
  position: relative;
}

.dropdown-trigger,
.dropdown-option {
  border: 0.1rem solid #00a1e1;
  color: #161616;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.09rem;
  line-height: 1.25;
  padding: 1.5rem 2.2rem 1.3rem;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.dropdown-trigger {
  align-items: center;
  display: flex;
  text-align: left;
}

.dropdown-trigger-text {
  margin-right: 0.8rem;
}

.dropdown_dark .dropdown-trigger {
  color: #ffffff;
  font-weight: 300;
}

.half-width-dropdown {
  width: 50%;
}

.dropdown_simple .dropdown-trigger {
  border-color: transparent;
}

.dropdown-trigger-text_ellipse {
  max-width: 158px;
  max-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-option {
  background: #ffffff;
  border: 0;
  transition: all 0.35s ease-in-out;
}

.dropdown_dark .dropdown-option {
  background: #161616;
  color: #00a1e1;
  font-weight: 300;
}

.dropdown-option:hover,
.dropdown-option:focus {
  background: #00a1e1;
  color: #ffffff;
}

.dropdown-list {
  display: block;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: translateY(-.1rem) scale(1, 0);
          transform: translateY(-.1rem) scale(1, 0);
  -webkit-transform-origin: top;
          transform-origin: top;
  top: 100%;
  z-index: 1;
}

.dropdown-list > * + * {
  border-top: 0.1rem solid #00a1e1;
}

.dropdown.show .dropdown-list {
  border: 0.1rem solid #00a1e1;
  -webkit-transform: translateY(-.1rem) scale(1);
          transform: translateY(-.1rem) scale(1);
}

.dropdown_simple.show .dropdown-trigger {
  border-color: #00a1e1;
}

.ResourceCheckboxInput {
  margin: 1em;
  width:100%;
}

.ResourceCheckboxInput.state,
.ResourceCheckboxInput.zip_code{
  width: 41%;
}

.ResourceCheckboxInput .form-control {
  text-align: left;
  margin-top: .5em;
  font-weight: 400;
}

.ResourceCheckboxInput .form-control .label,
.ResourceCheckboxInput .form-control .input,
.ResourceCheckboxInput .link-container {
  font-size: 1em;
}

.ResourceCheckboxInput .form-control .label {
  color: #444d50;
  text-transform: uppercase;
}

.ResourceCheckboxInput .input-control {
  border: .1rem solid  #9D9D9D;
}

.ResourceCheckboxInput .form-control .input {
  background: transparent;
  border: none;
  font-weight: 400;
  font-size: .9em;
  color: #444d50;
  padding: .7em;
  width: 100%;
}

.ResourceCheckboxInput .form-control .input:focus {
  outline:none;
}

.ResourceCheckboxInput .link-container {
  margin-top: .2em;
  text-align: left;
}

.ResourceCheckboxInput .submit-button-container {
  display: flex;
  justify-content: center;
}

.control-group {
  display: inline-block;
  background: #fff;
  text-align: left;
  font-weight: 300;
  font-size: .8em;
}

.control {
  display: block;
  position: relative;
  padding-left: 2.3em;
  margin-top: 15px;
  cursor: pointer;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #fff;
}

.control input:checked ~ .control__indicator {
  background: #fff;
}

.control input:checked:focus ~ .control__indicator {
  background: #fff;
}

.control input:checked ~ .control__indicator:after {
  display: block;
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.ResourceCheckboxGroup {
  padding: 2.4rem 0;
  width: 100%;
}

.ResourceCheckboxGroup .filter-heading {
  font-size: 1.3em;
  text-transform: uppercase;
}

.form-container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin: 0 auto;
  max-width: 45rem;
  width: 100%;
}

.form-container_left {
  margin-left: 0;
}

.form-row + .form-row {
  margin-top: 2rem;
}

.form-row_lgTop {
  margin-top: 6.4rem;
}

.form-control {
  margin-top: .5em;
  text-align: left;
}

.form-control .label {
  color: #9D9D9D;
  font-size: 1.6rem;
  letter-spacing: 0.09rem;
  text-transform: uppercase;
}

.form-control .input {
  background: transparent;
  border: 0;
  border-bottom: .1rem solid  #9D9D9D;
  color: #ffffff;
  font-size: 3.6rem;
  font-weight: 300;
  width: 100%;
}

.form-control .input:focus {
  border-color: #00a1e1;
  outline: 0;
}

.form-control .input_area {
  border: .1rem solid  #9D9D9D;
  font-size: 1.8rem;
  min-height: 30rem;
  padding: 0.8rem;
}

.form-control_light .label,
.form-control_light .input {
  color: #161616;
}

.form-control_light .input {
  border-color: #161616;
}

.link-container {
  margin-top: 2rem;
  text-align: left;
}

.link-container_alignCenter {
  text-align: center;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.form-control .error {
  font-size: 1.4rem;
}

.form-control .label_error,
.form-control .error {
  color: #fb541a;
}

.form-control .input_error,
.form-control .input_error:focus {
  border-color: #fb541a;
}

.form-control .error-item + .error-item {
  margin-top: 1.6rem;
}

.RadioButtonGroup .control--radio .control__indicator {
  border-radius: 50%;
}

.RadioButtonGroup .control--radio .control__indicator:after {
  background: #0097DD;
  border-radius: 50%;
  height: 13px;
  left: 50%;
  width: 13px;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.LocalePicker-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: auto;
}

.LocalePicker-heading {
  color: #161616;
  font-weight: 300;
  margin: 3.2rem auto 0 auto;
  text-transform: uppercase;
}

.LocalePicker-save {
  margin-top: 3.2rem;
}

.LocalePicker-cancel {
  margin-top: 2.4rem;
  text-decoration: none;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .LocalePicker-heading {
    margin: 0 auto 7rem;
  }

  .LocalePicker-save {
    margin-top: 7.2rem;
  }

  .LocalePicker-cancel {
    margin-top: 3.2rem;
  }
}

.TableHeadingSort{
  display: flex;
  flex-direction: column;
  padding: 0 .5em;
  cursor: pointer;
}

.TableHeadingSort .sort{
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  height: 0;
  width: 0;
}

.TableHeadingSort .descending{
  border-bottom: 5px solid gray;
  margin-bottom: .25em;
}

.TableHeadingSort .descending.active{
  border-bottom: 5px solid black;
}

.TableHeadingSort .ascending{
  border-top: 5px solid gray;
}

.TableHeadingSort .ascending.active{
  border-top: 5px solid black;
}
.Pagination {
  display: flex;
}

.Pagination a.pagination-link {
  color: #449FDB;
  cursor: pointer;
  display: block;
  font-weight: 400;
  font-weight: bold;
  padding: 0 .5em;
  text-decoration: none;
}

.Pagination a.pagination-link.current {
  color: black;
  pointer-events: none;
}

.Pagination a.pagination-link:first-child {
  padding-left: 0;
}

.Pagination a.pagination-link:last-child {
  padding-right: 0;
}

.SessionsSearchFiltersGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 0;
  margin-top: 1.6rem;
  overflow: hidden;
}

.SessionsSearchFiltersGroup .heading {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 1.1em;
  font-weight: 600;
}

.SessionsSearchFiltersGroup .filter-group-container {
  margin-bottom: 4rem;
  width: 100%;
}

.heading .filter-heading {
  display: none;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .SessionsSearchFiltersGroup .filter-group-container {
    width: 33%;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .SessionsSearchFiltersGroup {
    flex-direction: column;
    height: auto;
    margin-top: 0;
    overflow: unset;
  }

  .SessionsSearchFiltersGroup .filter-group-container {
    margin-bottom: 0;
    margin-top: 25%;
    width: auto;
  }

  .heading .filter-controls {
    display: none;
  }

  .heading .filter-heading {
    display: block;
  }
}

.control__indicator {
  background: #ffffff;
  border: solid 1px #0097DD;
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.control--checkbox {
  font-weight: 300;
}

.control--checkbox .control__indicator:after {
  border: solid #161616;
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
          transform: translateX(-50%) translateY(-50%) rotate(45deg);
  width: 3px;
}

.control--checkbox .filter-label-content .rating-star {
  border-bottom: .7em solid #000000;
  border-left: .3em solid transparent;
  border-right: .3em solid transparent;
  display: inline-block;
  font-size: 8px;
  height: 0;
  margin-left: .7em;
  margin-right: .7em;
  position: relative;
  top: -4px;
  width: 0;
}

.control--checkbox .filter-label-content .rating-star:before,
.control--checkbox .filter-label-content .rating-star:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: .4em;
  left: -1em;
  border-right: 1em solid transparent;
  border-bottom: .7em solid #000000;
  border-left: 1em solid transparent;
  -webkit-transform: rotate(-35deg);
          transform: rotate(-35deg);
}

.control--checkbox .filter-label-content .rating-star:after {
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
}

.Vertical-Bar-Chart .Bar {
  fill: #008BCD;
}

.Vertical-Bar-Chart.Axis path {
  stroke: none;
}

.Vertical-Bar-Chart.Axis text {
  font-weight: 300;
  font-size: 1.3em;
}

.Vertical-Bar-Chart.Axis-Left .tick line {
  stroke: none;
}

.Vertical-Bar-Chart.Axis-Left .tick line {
  stroke: #D3D3D3;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.Large-Vertical-Bar-Chart .Bar {
  fill: #008BCD;
}


.Axis path {
  stroke: none;
}

.XAxis-Labels g.tick text,
.YAxis-Labels g.tick text
{
  font-weight: 300;
  font-size: 1.3em;
}

.XAxis-Labels g.tick line,
.YAxis-Labels g.tick line{
  stroke: none;
}

.XAxis-Ticks g.tick text,
.YAxis-Ticks g.tick text {
  fill: none;
}

.YAxis-Ticks g.tick line,
.XAxis-Ticks g.tick line
{
  stroke: #c1c1c1;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.Dougnut-Legend text.Quantity{
  font-weight: 300;
  font-size: 1.5em;
}
.Horizontal-Bar-Chart text{
  font-size: 11px;
  font-weight: 400;
  font-weight: bolder;
}

.Horizontal-Bar-Chart .Bar {
  fill: #008BCD;
}

.Horizontal-Bar-Chart.Axis path{
  fill: none;
  stroke: none;
}

.Horizontal-Bar-Chart.Axis-Left .tick line {
  stroke: none;
}

.Horizontal-Bar-Chart.Axis-Left text {
  fill: none;
}

.Horizontal-Bar-Chart.Axis-Bottom .tick line {
  stroke: #D3D3D3;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.search-filter-button-group {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.search-filter {
  margin-top: 1.6rem;
}

.search-filter:first-child {
  margin-top: 0;
}

.dropdown-filter {
  flex: 1 1;
}

.datepicker-filter {
  flex: 2 1;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .search-filter-button-group {
    flex-direction: row;
  }

  .search-filter {
    margin-right: .5em;
    margin-top: 0;
  }
}

.dropdown {
  position: relative;
}

.dropdown-trigger,
.dropdown-option {
  border: 0.1rem solid #00a1e1;
  color: #161616;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.09rem;
  line-height: 1.25;
  padding: 1.5rem 2.2rem 1.3rem;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.dropdown-trigger {
  align-items: center;
  display: flex;
  text-align: left;
}

.dropdown-trigger-text {
  margin-right: 0.8rem;
}

.dropdown_dark .dropdown-trigger {
  color: #ffffff;
  font-weight: 300;
}

.half-width-dropdown {
  width: 50%;
}

.dropdown_simple .dropdown-trigger {
  border-color: transparent;
}

.dropdown-trigger-text_ellipse {
  max-width: 158px;
  max-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-option {
  background: #ffffff;
  border: 0;
  transition: all 0.35s ease-in-out;
}

.dropdown_dark .dropdown-option {
  background: #161616;
  color: #00a1e1;
  font-weight: 300;
}

.dropdown-option:hover,
.dropdown-option:focus {
  background: #00a1e1;
  color: #ffffff;
}

.dropdown-list {
  display: block;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: translateY(-.1rem) scale(1, 0);
          transform: translateY(-.1rem) scale(1, 0);
  -webkit-transform-origin: top;
          transform-origin: top;
  top: 100%;
  z-index: 1;
}

.dropdown-list > * + * {
  border-top: 0.1rem solid #00a1e1;
}

.dropdown.show .dropdown-list {
  border: 0.1rem solid #00a1e1;
  -webkit-transform: translateY(-.1rem) scale(1);
          transform: translateY(-.1rem) scale(1);
}

.dropdown_simple.show .dropdown-trigger {
  border-color: #00a1e1;
}

.analytics-filters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;;
  text-transform: uppercase;
  font-weight: 400;
}

.search-filters-container,
.page-filters-container {
  display: flex;
  flex-direction: column;
}

.page-filters-container {
  flex: 1 1;
}

.search-filters-container {
  flex-grow: 2;
  margin-top: 3.2rem;
}

.page-filter-button-group {
  display: flex;
}

.filter-label {
  padding-bottom: 1.5rem;
  font-size: 1.8rem;
}

.filter-item {
  display: block;
  border: 0.1rem solid #00a1e1;
  color: #161616;
  letter-spacing: 0.09rem;
  line-height: 1.2;
  padding: 1rem 1.5rem;
  font-size: .9em;
  text-align: center;
  text-decoration: none;
}

.filter-item.active {
  color: white;
  background: #449FDB;
}

.filter-item:visited {
  color: #161616;
}

.filter-item.active:visited {
  color: #ffffff;
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .analytics-filters {
    flex-direction: row;
  }

  .search-filters-container {
    margin-left: 6em;
    margin-top: 0;
  }
}

.tooltip {
  display: inline-block;
  height: 2.5rem;
  position: absolute;
  vertical-align: middle;
  width: 2.5rem;
}

.tooltip-body {
  background: #161616;
  border: 0.1rem solid #00a1e1;
  color: #ffffff;
  display: none;
  font-size: 1.4rem;
  left: 100%;
  line-height: 1.3;
  min-width: 30rem;
  padding: 1.6rem;
  position: absolute;
  text-transform: none;
  top: 0;
  z-index: 1;
}

.tooltip-body a {
  color: #00a1e1;
  text-decoration: underline;
}

.tooltip-body_visible {
  display: block;
}

.tooltip-body_bottomLeft {
  left: 0;
  top: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.tooltip-trigger {
  display: block;
}

.icon_information {
  border: 0.1rem solid #00a1e1;
  border-radius: 50%;
}

.icon_information > * {
  color: #00a1e1;
  font-size: 1.8rem;
  left: 50%;
  line-height: 1;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.metric {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  justify-content: space-between;
  padding: 2rem 0 0;
  position: relative;
  text-transform: uppercase;
}

.metric-type {
  font-weight: 400;
  color: #9d9d9d;
  font-size: 1.8rem;
  line-height: 2;
}

.metric-name {
  font-weight: 400;
  font-size: 3.2rem;
  color: white;
  letter-spacing: 0.2rem;
}

.metric-name > * {
  display: inline-block;
  margin-right: 1rem;
  vertical-align: middle;
}

.metric-quantity {
  font-weight: 300;
  font-size: 7.2rem;
  color: white;
  line-height: 1.75;
}

.metric .tooltip {
  right: 0;
  top: 1.6rem;
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .metric-quantity {
    font-size: 10rem;
  }
}

.MetricsGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.MetricsGroup .metric-container {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

.MetricsGroup .border-tip-container {
  border-top: #3881af 1px solid;
  position: relative;
}

.MetricsGroup .border-tip {
  border-radius: 50%;
  border: solid 1px #0097DD;
  color: #0097DD;
  height: 6px;
  position: absolute;
  width: 6px;
}

.MetricsGroup .border-tip-top {
  left: -6px;
  top: -4px;
}

.MetricsGroup .border-tip-bottom {
  right: -6px;
  top: -4px;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .MetricsGroup {
    flex-direction: row;
  }

  .MetricsGroup .metric-container {
    width: 33%;
  }

  .MetricsGroup .border-tip-container {
    border-left: #3881af 1px solid;
    border-right: #3881af 1px solid;
    border-top: none;
  }

  .MetricsGroup .border-tip-top {
    left: -3px;
    top: -6px;
  }

  .MetricsGroup .border-tip-bottom {
    left: -3px;
    right: unset;
    top: 100%;
  }
}

.Analytics {
  display: flex;
  flex-direction: column;
}

.Analytics .product-group-container {
  display: flex;
  flex-direction: column;
  margin-top: 7.2rem;
  width: 100%;
}

.Analytics h2.product-name {
  font-weight: 300;
  font-size: 2.4rem;
  text-transform: uppercase;
}

.chart-group-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3.6rem;
  width: 100%;
}

.chart-group-container.full.left .chart-group {
  margin: 0;
}

.chart-group-container.full .chart-group {
  margin: 0 auto;
}

.chart-group {
  width: 100%;
}

.chart-group + .chart-group {
  margin-top: 3.6rem;
}

.chart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chart {
  font-weight: 400;
  position: relative;
  width: 100%;
  padding-bottom: 60%;
}

.chart_horizontalBar {
  padding-bottom: 50rem;
}

.donut-chart {
  max-height: 30rem;
}

.chart svg {
  height: 100%;
  left: 50%;
  overflow: visible;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.chart-label {
  color: #444d50;
  font-weight: 400;
  font-size: 1.6rem;
  font-weight:bold;
  margin-bottom: 4rem;
  width: 100%;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .chart-group-container {
    flex-direction: row;
  }

  .chart-group {
    width: 40%;
  }

  .chart-group-container.full.left .chart-group {
    width: 80%;
  }

  .chart-group-container.full .chart-group {
    width: 80%;
  }

  .chart-group-container.full .chart,
  .chart_full {
    padding-bottom: 40%;
  }
}

.EmployeesTable td div.modify-resource-options {
  display: flex;
  justify-content: space-between;
  color: #449FDB;
  text-transform: none;
}

.EmployeesTable td div.shops-list-container {
  display: flex;
  flex-direction: column;
}

.EmployeesTable td div.shops-list-container div.shop {
  margin-bottom: .25em;
}

.EmployeesTable td div.modify-resource-options div {
  padding: 0 .1em;
}

/* Override Gigya's "width: 450px" style... this looks better on mobile */
.SignInForm .gigya-screen .passport-input {
    max-width: 45rem;
    width: 100%;
}

.SignInForm .gigya-screen input[type='submit'].gigya-input-submit {
    margin: 0 auto;
}

.set-hd {
    color: #fff;
    line-height: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    vertical-align: baseline;
}

.set-bd_super {
    margin-top: 6.4rem;
}

.set-bd {
    margin-top: 0.8rem;
    width: 100%;
}

.hidden {
    display: none;
}

.set {
    color: #fff;
    line-height: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    vertical-align: baseline;
    display: block;
    width: 100%;
}

.set_alignCenter {
    text-align: center;
}

.btn_fixedWidth {
    width: 100%;
}

.btn {
    background: transparent;
    border: 0.1rem solid #00a1e1;
    color: #00a1e1;
    font-family: tk-klavika-web-n3, sans-serif;
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
    line-height: 1.2;
    height: auto;
    outline: none;
    padding: 1.8rem 2.4rem 1.5rem;
    text-align: center;
    text-transform: uppercase;
    width: auto;
    -webkit-appearance: none;
    z-index: 2;
}

input[type='checkbox'] {
    margin-top: 1.5rem;
}

label {
    color: #fff;
}

/* $COLOR_DEFAULT_BLUE: #00a1e1;
$COLOR_DEFAULT_GREEN: #80bb41;
$COLOR_DEFAULT_ORANGE: #fb541a; */

.field_hasError .field-label {
    color: #fb541a;
}

.field_hasError .field-input {
    border-color: #fb541a;
    color: #fb541a;
}

.field_hasError .field-error {
    visibility: visible;
}

/* .field-error {
    font-family: tk-klavika-web-n4, sans-serif;
    font-size: 1.3rem;
    position: absolute;
    top: 100%;
    -ms-transform: translateY(0.8rem);
    transform: translateY(0.8rem);
    visibility: hidden;
} */

.field-error {
    line-height: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    vertical-align: baseline;
    font-family: tk-klavika-web-n4, sans-serif;
    font-size: 1.3rem;
    position: absolute;
    top: 100%;
    -webkit-transform: translateY(0.8rem);
            transform: translateY(0.8rem);
    color: #fb541a;
    visibility: hidden;
}

.field {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
}

.field-label {
    line-height: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    vertical-align: baseline;
    color: #9d9d9d;
    display: block;
    font-family: tk-klavika-web-n3, sans-serif;
    font-size: 1.6rem;
    letter-spacing: 0.09rem;
    margin-bottom: 0.8rem;
    text-transform: uppercase;
    width: 100%;
    opacity: 1;
    transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.field-input {
    box-sizing: border-box;
    -webkit-appearance: none;
            appearance: none;
    background-color: transparent;
    border: none;
    border-bottom: 0.1rem solid #9d9d9d;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    display: block;
    font-family: tk-klavika-web-n3, sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    transition: border-color 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    font-size: 3.6rem;
}

/* Override Gigya's "width: 450px" style... this looks better on mobile */
.SignInForm .gigya-screen .passport-input {
    max-width: 45rem;
    width: 100%;
}

.SignInForm .gigya-screen input[type='submit'].gigya-input-submit {
    margin: 0 auto;
}

.form-messages_hasMessage {
    display: block;
    margin-bottom: 2.4rem;
}

.session-details {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 12rem;
  padding: 2.4rem 0;
  width: 100%;
}
.h2_padded {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.session-details_top {
  padding-top: 4rem;
}

.session-details_list {
  margin: 1.5rem 0;
}

.spaceAboveBelow {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.session-details_top-right {
  padding-top: 3.2rem;
}

.session-details_lower-bottom {
  margin: 5rem 0;
}

.session-details_transactions_txt {
  padding: 1rem 0 7rem 0;
}

.headings {
  font-size: 1.6rem;
  font-weight: 300;
  text-transform: uppercase;
}

.headings th {
  padding: 0 7.2rem 1rem 1rem;
}

.tableData {
  font-size: 1.4rem;
}

.tableData td {
  font-size: 1.4rem;
  font-weight: 300;
  padding: 1rem;
  text-transform: capitalize;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .session-details {
    padding: 8rem 0 12rem;
  }

  .session-details_top {
    display: flex;
    /*height: 30%;*/
    padding-top: 5.6rem;
  }

  .session-details_upper-bottom {
    max-width: 50%;
  }

  .session-details_top-left {
    width: 70%;
  }

  .session-details_top-right {
    width: 30%
  }
}

.PageLink {
  color: #00a1e1;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
}

.PageLink_normal {
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: none;
}

.PageLink_heavy {
  font-size: 1.8rem;
  font-weight: 600;
}

.NavigationLinkIcon {
  display: inline-block;
  margin-left: auto;
  margin-right: 1rem;
  transition: all .5s cubic-bezier(.25, .46, .45, .94);
  -webkit-transform-origin: 50% 43%;
          transform-origin: 50% 43%;
  -webkit-transform: rotate(-180deg) translateZ(0);
          transform: rotate(-180deg) translateZ(0);
}

.NavigationLinkIcon {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.NavigationLinkIcon i {
  fill: #00a1e1;
  height: 1.4rem;
  width: 1.4rem;
  display: inline-block;
  padding-right: 2rem;
  position: relative;
}

.NavigationLinkIcon i > * {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.session-products {
  overflow-y: visible;
}

.productList {
  margin-top: 2rem !important;
}
/* ---------------------------------------------------------------------
 Badge
 --
 Container for an icon and label badge layout
------------------------------------------------------------------------ */

.badge {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.badge-icon,
.badge-label {
  display: inline-block;
}

.badge-icon {
  background: #ffffff;
  border: 0.1rem solid #161616;
  border-radius: 50%;
  position: relative;
  height: 4.8rem;
  width: 4.8rem;
}

.badge-icon > svg {
  height: 2rem;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 2rem;
}

.badge-label {
  margin-left: 1.6rem;
}

.mix-badge-icon_colorDefaultBlue {
  border-color: #00a1e1;
  stroke: #00a1e1;
}

.mix-badge-icon_colorDarkGray {
  border-color: #161616;
  stroke: #161616;
}

.session-products {
  overflow-y: visible;
}

.productList {
  margin-top: 2rem !important;
}
.form-messages_hasMessage {
    font-size: 100%;
}

.form-messages_hasMessage li {
    display: block;
    margin-bottom: 2.4rem;
    margin-top: 1.6rem;
}

.form-messages_hasMessage a {
    color: inherit;
}

.Navigation {
  height: 12rem;
  left: 0;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
}

.Navigation-container {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  padding: 0 1.6rem;
  width: 100%;
}

.Navigation-left {
  margin-right: auto;
  width: 20%;
}

.Navigation-right {
  margin-left: auto;
  width: 20%;
}

.Navigation-center {
  width: 60%;
}

.Navigation-list {
  display: flex;
  justify-content: center;
}

.Navigation-list > * + * {
  margin-left: 3.2rem;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .Navigation-container {
    max-width: 768px;
    padding: 0 3.2rem;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .Navigation-container {
    max-width: 128rem;
    padding: 0 6.4rem;
  }
}

.Logo {
  display: inline-block;
  height: 2.4rem;
  width: 6.2rem;
}

.Logo_block {
  display: block;
}

.Logo_sm {
  height: 2rem;
  width: 5rem;
}

.Logo > svg {
  fill: #00a1e1;
  height: 100%;
  width: 100%;
}

.Logo_colorDarkGray > svg {
  fill: #8b8b8b;
}

.NavigationLink{
  display: block;
  color: #00a1e1;
  font-weight: 300;
  padding-bottom: .4rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  top: 0.2rem;
}

.NavigationLink.active {
  color: #ffffff;
  border-bottom: 1px solid #449FDB;
}

.NavigationDropDown .dropdown-list {
  min-width: 20rem;
  width: 100%;
}

/* ----------------------
 FOOTER STYLES
------------------------- */

.footer {
  background: #292929;
  display: block;
  flex-wrap: nowrap;
}

.footer-inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2.4rem 1.6rem;
}

.footerLogo {
  text-align: center;
  width: auto;
}

.footerInfo {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 2 1;
  justify-content: flex-end;
  margin-top: 2.4rem;
  width: auto;
}

.footerInfo > * + * {
  margin-top: 1.6rem;
}

.footerLink {
  color: #8b8b8b;
  font-size: 1.4rem;
  position: relative;
  text-decoration: none;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .footer-inner {
    flex-direction: row;
    max-width: 768px;
    padding: 2.4rem 3.2rem;
  }

  .footerInfo {
    flex-direction: row;
    margin-top: 0;
  }

  .footerLink {
    padding-right: 2.4rem;
  }

  .footerInfo > * + * {
    margin-top: 0;
  }

  .footerInfo > *:not(:first-child) {
    margin-left: 2rem;
  }

  .footerLink::after {
    border-right: 0.1rem solid #8b8b8b;
    content: '';
    display: inline-block;
    height: 1.6rem;
    width: 0.1rem;
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .footer-inner {
    max-width: 128rem;
    padding: 2.4rem 6.4rem;
  }
}

/* ---------------------------------------------------------------------
RESET CSS http://meyerweb.com/eric/tools/css/reset/
------------------------------------------------------------------------ */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  overflow-y: auto;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

/* ---------------------------------------------------------------------
 HTML5 Reset
------------------------------------------------------------------------ */

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

audio, canvas, video, progress, picture {
  display: inline-block;
}

template {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.box {
  display: block;
}

.box_top1 {
  padding-top: 0.8rem;
}

.box_top2 {
  padding-top: 1.6rem;
}

.box_top3 {
  padding-top: 2.4rem;
}

.box_top4 {
  padding-top: 3.2rem;
}

.box_top5 {
  /*padding-top: 4rem;*/
}

.box_top10 {
  padding-top: 8rem;
}

.box_alignRight {
  text-align: right;
}

.box_alignCenter {
  text-align: center;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .box_top7Md {
    padding-top: 5.6rem;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .box_top10Lg {
    padding-top: 8rem;
  }
  .box_top7Md {
    padding-top: 0rem;
  }
}

/* 12 column grid */
.grid {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 1rem;
  margin-left: 1rem;
}

.grid_columnReverse {
  flex-direction: column-reverse;
}

.grid_reverse {
  flex-direction: row-reverse;
}

.column {
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.column_dividerRight {
  border-right: 1px solid #A8A8A8;
}

.column_1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.column_2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.column_3 {
  flex-basis: 25%;
  max-width: 25%;
}

.column_4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.column_5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.column_6 {
  flex-basis: 50%;
  max-width: 50%;
}

.column_7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.column_8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.column_9 {
  flex-basis: 75%;
  max-width: 75%;
}

.column_10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.column_11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.column_12 {
  flex-basis: 100%;
  max-width: 100%;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .grid_rowMd {
    flex-direction: row;
  }

  .column_1Md {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .column_2Md {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .column_3Md {
    flex-basis: 25%;
    max-width: 25%;
  }

  .column_4Md {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .column_5Md {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .column_6Md {
    flex-basis: 50%;
    max-width: 50%;
  }

  .column_7Md {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .column_8Md {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .column_9Md {
    flex-basis: 75%;
    max-width: 75%;
  }

  .column_10Md {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .column_11Md {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .column_12Md {
    flex-basis: 100%;
    max-width: 100%;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .column_dividerRightLg {
    border-right: 1px solid #A8A8A8;
  }

  .column_1Lg {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .column_2Lg {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .column_3Lg {
    flex-basis: 25%;
    max-width: 25%;
  }

  .column_4Lg {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .column_5Lg {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .column_6Lg {
    flex-basis: 50%;
    max-width: 50%;
  }

  .column_7Lg {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .column_8Lg {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .column_9Lg {
    flex-basis: 75%;
    max-width: 75%;
  }

  .column_10Lg {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .column_11Lg {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .column_12Lg {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
}

.modal-overlay {
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  overflow-y: auto  ;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.modal-overlay_dark {
  background: #161616;
}

.modal {
  padding: 4rem 0;
}

.modal-content-top {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow-y: auto;
  padding: 4.8rem 1.6rem;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.modal-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow-y: auto;
  padding: 4.8rem 1.6rem;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.modal-close {
  fill: #161616;
  stroke: #161616;
  height: 2rem;
  position: absolute;
  right: 2.4rem;
  top: 2.4rem;
  width: 2rem;
}

.modal-overlay_dark .modal-close {
  fill: #00a1e1;
  stroke: #00a1e1;
}

.modal-body {
  font-size: 1.1em;
  font-weight: 400;
  padding: 2em;
  text-align: center;
}

.modal-footer {
  text-align: center;
}

.modal-link {
  color: #00a1e1;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .modal-content {
    justify-content: center;
    max-width: 768px;
    padding: 4.8rem 1.6rem;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .modal {
    display: flex;
    height: 100%;
  }

  .modal-content {
    flex-direction: row;
    max-width: 128rem;
    padding: 4.8rem 6.4rem;
  }
}

.TableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.Table {
  border-spacing: 0.5rem;
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.Table_spaced {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.Table td,
.Table th {
  font-size: 1.4rem;
  padding: 1.6rem 4rem 1.6rem 0;
}

.Table th {
  text-transform: uppercase;
  vertical-align: top;
}

.Table td {
  vertical-align: top;
}

.Table td a {
  color: #00a1e1;
  text-decoration: none;
  text-transform: uppercase;
}

.Table td div.modify-resource-options {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Table td div.modify-resource-options > * + * {
  margin-left: 0.8rem;
}

.Table-cell_constrained {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Table .th_sortable {
  position: relative;
}

.Table .th_sortable::before {
  border-bottom: 0.5rem solid #000000;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  content: ' ';
  display: block;
  position: absolute;
  right: -1.8rem;
  top: 0.2rem;
}

.Table .th_sortable::after {
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid #000000;
  content: ' ';
  display: block;
  position: absolute;
  right: -1.8rem;
  top: 1rem;
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .Table td div.modify-resource-options > * + * {
    margin-left: 0;
  }
}

/* ----------------------
 TEXT STYLES
------------------------- */

.txt {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
}

.txt_sm {
  font-size: 1.4rem;
}

.txt_md {
  font-size: 1.8rem;
}

.text_white {
  color: #ffffff;
}

.text_loading_message {
  margin-top: 2em;
}

.txt_mdLg {
  font-size: 2.4rem;
}

.txt_lg {
  font-size: 3.2rem;
}

.txt_lgMobile {
  font-size: 3.2rem;
}

.txt_xl,
.txt_huge {
  font-size: 4.8rem;
}

.txt_giant {
  font-size: 10.4rem;
}

.txt_lgPlus {
  font-size: 3.6rem;
}

.txt_lgMobile {
  font-size: 4.8rem;
}

.txt_huge {
  font-size: 7.2rem;
}

.txt_link {
  color: #00a1e1;
  text-decoration: none;
}

/* ----------------------
 TEXT WEIGHT
------------------------- */

.txt_light {
  font-weight: 300;
}

.txt_normal {
  font-weight: 400;
}

.txt_medium {
  font-weight: 500;
}

.txt_bold {
  font-weight: 600;
}

/* ----------------------
 TEXT COLORS
------------------------- */

.text_colorDarkGray {
  color: #9d9d9d;
}

.mix-txt_colorDefaultBlue {
  color: #00a1e1;
}

.mix-txt_colorLightest {
  color: #ffffff;
}

.mix-txt_colorDefaultGray {
  color: #9d9d9d;
}

.mix-txt_colorRegularGray {
  color: #8b8b8b;
}

.mix-txt_colorDarkGray {
  color: #666666;
}

.mix-txt_colorDarkest {
  color: #161616;
}

.mix-txt_colorDefaultOrange {
  color: #fb541a;
}

/* ----------------------
 TEXT TRANSFORMS
------------------------- */

.mix-txt_capitalize {
  text-transform: capitalize;
}

.mix-txt_lowercase {
  text-transform: lowercase;
}

.mix-txt_uppercase {
  letter-spacing: 0.09rem;
  text-transform: uppercase;
}

.mix-txt_uppercaseLg {
  letter-spacing: 0.13rem;
  text-transform: uppercase;
}

.mix-txt_squeeze {
  letter-spacing: -0.2rem;
}

.mix-txt_underline {
  text-decoration: underline;
}

.mix-txt_wrap {
  word-wrap: break-word;
}

/* ----------------------
 TEXT ALIGN
------------------------- */

.mix-txt_leftAlign {
  text-align: left;
}

.mix-txt_centerAlign {
  text-align: center;
}

.mix-txt_rightAlign {
  text-align: right;
}

/* ----------------------
 TEXT HEIGHT
------------------------- */

.mix-txt_flatHeight {
  line-height: 1;
}

.mix-txt_mediumHeight {
  line-height: 1.33;
}

.mix-txt_largeHeight {
  line-height: 1.5;
}

/* ----------------------
 TEXT HORIZONTAL SPACING
------------------------- */

.mix-txt_divider {
  display: inline-block;
  margin: 0 rhythm(1);
}

.vList {
  margin: 0;
  padding: 0;
}

.vList_1 > * + * {
  margin-top: 0.8rem;
}

.vList_2 > * + * {
  margin-top: 1.6rem;
}

.vList_3 > * + * {
  margin-top: 2.4rem;
}

.vList_4 > * + * {
  margin-top: 3.2rem;
}

.vList_5 > * + * {
  margin-top: 4rem;
}

/* ---------------------------------------------------------------------
 Set
 --
 A Set of Related Things... usually heading, body, footer
------------------------------------------------------------------------ */

.set {
  display: block;
  width: 100%;
}

.set-bd {
  margin-top: 0.8rem;
  width: 100%;
}

.set-bd_flush {
  margin-top: 0;
}

.set-bd_compact {
  margin-top: 0.4rem);
}

.set-bd_detached {
  margin-top: 1.6rem;
}

.set-bd_separated {
  margin-top: 2.4rem;
}

.set-bd_disengaged {
  margin-top: 3.2rem;
}

.set-bd_mega {
  margin-top: 4rem;
}

.set-bd_giant {
  margin-top: 5.6rem;
}

.set-bd_super {
  margin-top: 6.4rem;
}

.set-bd_isolated {
  margin-top: 8rem;
}

.mix-set-bd_scrollable {
  overflow: auto;
}

.mix-set_narrow {
  max-width: 25rem;
}

.mix-set_centered {
  margin-left: auto;
  margin-right: auto;
}

.mix-set_constrained {
  margin-left: auto;
  margin-right: auto;
  max-width: 26rem;
}

.mix-set_alignLeft,
.mix-set-bd_alignLeft {
  align-items: left;
  text-align: left;
}

.mix-set_alignCenter,
.mix-set-bd_alignCenter {
  text-align: center;
}

.mix-set-bd_borderTop {
  border-top: 0.1rem solid $COLOR_MEDIUM_GRAY;
}

.mix-set-bd_positionLeftMobile {
  left: 0;
  position: absolute;
}

.mix-set_constrained {
  max-width: 37.7rem;
}

.mix-set-bd_positionLeftMobile {
  position: static;
}

.set-bd_isolated_mdLg {
  margin-top: 10rem;
}

.mix-set_slim {
  max-width: 37.7rem;
}

.mix-set_wide {
  max-width: 55.6rem;
}

/* ---------------------------------------------------------------------
 Element Styles
------------------------------------------------------------------------ */

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    overflow-y: scroll;
}

body {
    background: #161616;
    font-family: 'klavika-web', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
}

html,
body,
#app,
.site {
    height: 100%;
    width: 100%;
}

.site {
    display: flex;
    flex-direction: column;
}

.site-main {
    display: flex;
    flex: 1 0 auto;
}

.site-footer {
    flex: none;
}

.container {
    margin: 0 auto;
    padding: 0 1.6rem;
    width: 100%;
}

button {
    -webkit-appearance: none;
            appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    font-family: 'klavika-web', sans-serif;
    font-size: 1.6rem;
    padding: 0;
}

.icon {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    width: 100%;
}

/* TABLET STYLES */

@media only screen and (min-width: 768px) {
    .container {
        max-width: 768px;
        padding: 0 1.6rem;
    }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
    .container {
        max-width: 128rem;
        padding: 0 6.4rem;
    }
}

.btn:disabled {
    border: 0.1rem solid #666;
    color: #666;
}

.form-messages_hasMessage a {
    color: #fff;
}

.filter-label-text {
    color: black;
}

.filter-label-content {
    color: black;
}

