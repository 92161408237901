.MetricsGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.MetricsGroup .metric-container {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

.MetricsGroup .border-tip-container {
  border-top: #3881af 1px solid;
  position: relative;
}

.MetricsGroup .border-tip {
  border-radius: 50%;
  border: solid 1px #0097DD;
  color: #0097DD;
  height: 6px;
  position: absolute;
  width: 6px;
}

.MetricsGroup .border-tip-top {
  left: -6px;
  top: -4px;
}

.MetricsGroup .border-tip-bottom {
  right: -6px;
  top: -4px;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .MetricsGroup {
    flex-direction: row;
  }

  .MetricsGroup .metric-container {
    width: 33%;
  }

  .MetricsGroup .border-tip-container {
    border-left: #3881af 1px solid;
    border-right: #3881af 1px solid;
    border-top: none;
  }

  .MetricsGroup .border-tip-top {
    left: -3px;
    top: -6px;
  }

  .MetricsGroup .border-tip-bottom {
    left: -3px;
    right: unset;
    top: 100%;
  }
}
