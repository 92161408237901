.Pagination {
  display: flex;
}

.Pagination a.pagination-link {
  color: #449FDB;
  cursor: pointer;
  display: block;
  font-weight: 400;
  font-weight: bold;
  padding: 0 .5em;
  text-decoration: none;
}

.Pagination a.pagination-link.current {
  color: black;
  pointer-events: none;
}

.Pagination a.pagination-link:first-child {
  padding-left: 0;
}

.Pagination a.pagination-link:last-child {
  padding-right: 0;
}
