.loader {
  background: #00b6ff;
  height: 0.5rem;
  position: absolute;
  right: 100%;
  top: 0;
  width: 100vw;
  z-index: 1;
}

.loader_fixed {
  position: fixed;
}
