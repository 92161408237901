.control__indicator {
  background: #ffffff;
  border: solid 1px #0097DD;
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.control--checkbox {
  font-weight: 300;
}

.control--checkbox .control__indicator:after {
  border: solid #161616;
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  width: 3px;
}

.control--checkbox .filter-label-content .rating-star {
  border-bottom: .7em solid #000000;
  border-left: .3em solid transparent;
  border-right: .3em solid transparent;
  display: inline-block;
  font-size: 8px;
  height: 0;
  margin-left: .7em;
  margin-right: .7em;
  position: relative;
  top: -4px;
  width: 0;
}

.control--checkbox .filter-label-content .rating-star:before,
.control--checkbox .filter-label-content .rating-star:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: .4em;
  left: -1em;
  border-right: 1em solid transparent;
  border-bottom: .7em solid #000000;
  border-left: 1em solid transparent;
  transform: rotate(-35deg);
}

.control--checkbox .filter-label-content .rating-star:after {
  transform: rotate(35deg);
}
