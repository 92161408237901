.form-messages_hasMessage {
    font-size: 100%;
}

.form-messages_hasMessage li {
    display: block;
    margin-bottom: 2.4rem;
    margin-top: 1.6rem;
}

.form-messages_hasMessage a {
    color: inherit;
}
