.vList {
  margin: 0;
  padding: 0;
}

.vList_1 > * + * {
  margin-top: 0.8rem;
}

.vList_2 > * + * {
  margin-top: 1.6rem;
}

.vList_3 > * + * {
  margin-top: 2.4rem;
}

.vList_4 > * + * {
  margin-top: 3.2rem;
}

.vList_5 > * + * {
  margin-top: 4rem;
}
