.Intro .header {
  line-height: 1.1;
  text-align: center;
  display: block;
  font-weight: 400;
  font-size: 4.8rem;
  color: white;
}

.Intro .summary {
  line-height: 1.33;
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  color: #9D9D9D;
  max-width: 34rem;
  margin: 4rem auto 0;
}

.Intro .summary_wide {
  max-width: 48rem;
}

@media only screen and (min-width: 768px) {
  .Intro .header {
    font-size: 7.2rem;
  }
}
