.dropdown {
  position: relative;
}

.dropdown-trigger,
.dropdown-option {
  border: 0.1rem solid #00a1e1;
  color: #161616;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.09rem;
  line-height: 1.25;
  padding: 1.5rem 2.2rem 1.3rem;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.dropdown-trigger {
  align-items: center;
  display: flex;
  text-align: left;
}

.dropdown-trigger-text {
  margin-right: 0.8rem;
}

.dropdown_dark .dropdown-trigger {
  color: #ffffff;
  font-weight: 300;
}

.half-width-dropdown {
  width: 50%;
}

.dropdown_simple .dropdown-trigger {
  border-color: transparent;
}

.dropdown-trigger-text_ellipse {
  max-width: 158px;
  max-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-option {
  background: #ffffff;
  border: 0;
  transition: all 0.35s ease-in-out;
}

.dropdown_dark .dropdown-option {
  background: #161616;
  color: #00a1e1;
  font-weight: 300;
}

.dropdown-option:hover,
.dropdown-option:focus {
  background: #00a1e1;
  color: #ffffff;
}

.dropdown-list {
  display: block;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateY(-.1rem) scale(1, 0);
  transform-origin: top;
  top: 100%;
  z-index: 1;
}

.dropdown-list > * + * {
  border-top: 0.1rem solid #00a1e1;
}

.dropdown.show .dropdown-list {
  border: 0.1rem solid #00a1e1;
  transform: translateY(-.1rem) scale(1);
}

.dropdown_simple.show .dropdown-trigger {
  border-color: #00a1e1;
}
