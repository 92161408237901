.box {
  display: block;
}

.box_top1 {
  padding-top: 0.8rem;
}

.box_top2 {
  padding-top: 1.6rem;
}

.box_top3 {
  padding-top: 2.4rem;
}

.box_top4 {
  padding-top: 3.2rem;
}

.box_top5 {
  /*padding-top: 4rem;*/
}

.box_top10 {
  padding-top: 8rem;
}

.box_alignRight {
  text-align: right;
}

.box_alignCenter {
  text-align: center;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .box_top7Md {
    padding-top: 5.6rem;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .box_top10Lg {
    padding-top: 8rem;
  }
  .box_top7Md {
    padding-top: 0rem;
  }
}
