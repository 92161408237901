/* 12 column grid */
.grid {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 1rem;
  margin-left: 1rem;
}

.grid_columnReverse {
  flex-direction: column-reverse;
}

.grid_reverse {
  flex-direction: row-reverse;
}

.column {
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.column_dividerRight {
  border-right: 1px solid #A8A8A8;
}

.column_1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.column_2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.column_3 {
  flex-basis: 25%;
  max-width: 25%;
}

.column_4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.column_5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.column_6 {
  flex-basis: 50%;
  max-width: 50%;
}

.column_7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.column_8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.column_9 {
  flex-basis: 75%;
  max-width: 75%;
}

.column_10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.column_11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.column_12 {
  flex-basis: 100%;
  max-width: 100%;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .grid_rowMd {
    flex-direction: row;
  }

  .column_1Md {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .column_2Md {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .column_3Md {
    flex-basis: 25%;
    max-width: 25%;
  }

  .column_4Md {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .column_5Md {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .column_6Md {
    flex-basis: 50%;
    max-width: 50%;
  }

  .column_7Md {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .column_8Md {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .column_9Md {
    flex-basis: 75%;
    max-width: 75%;
  }

  .column_10Md {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .column_11Md {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .column_12Md {
    flex-basis: 100%;
    max-width: 100%;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .column_dividerRightLg {
    border-right: 1px solid #A8A8A8;
  }

  .column_1Lg {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .column_2Lg {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .column_3Lg {
    flex-basis: 25%;
    max-width: 25%;
  }

  .column_4Lg {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .column_5Lg {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .column_6Lg {
    flex-basis: 50%;
    max-width: 50%;
  }

  .column_7Lg {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .column_8Lg {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .column_9Lg {
    flex-basis: 75%;
    max-width: 75%;
  }

  .column_10Lg {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .column_11Lg {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .column_12Lg {
    flex-basis: 100%;
    max-width: 100%;
  }
}
