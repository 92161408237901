.session-details {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 12rem;
  padding: 2.4rem 0;
  width: 100%;
}
.h2_padded {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.session-details_top {
  padding-top: 4rem;
}

.session-details_list {
  margin: 1.5rem 0;
}

.spaceAboveBelow {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.session-details_top-right {
  padding-top: 3.2rem;
}

.session-details_lower-bottom {
  margin: 5rem 0;
}

.session-details_transactions_txt {
  padding: 1rem 0 7rem 0;
}

.headings {
  font-size: 1.6rem;
  font-weight: 300;
  text-transform: uppercase;
}

.headings th {
  padding: 0 7.2rem 1rem 1rem;
}

.tableData {
  font-size: 1.4rem;
}

.tableData td {
  font-size: 1.4rem;
  font-weight: 300;
  padding: 1rem;
  text-transform: capitalize;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .session-details {
    padding: 8rem 0 12rem;
  }

  .session-details_top {
    display: flex;
    /*height: 30%;*/
    padding-top: 5.6rem;
  }

  .session-details_upper-bottom {
    max-width: 50%;
  }

  .session-details_top-left {
    width: 70%;
  }

  .session-details_top-right {
    width: 30%
  }
}
