.tooltip {
  display: inline-block;
  height: 2.5rem;
  position: absolute;
  vertical-align: middle;
  width: 2.5rem;
}

.tooltip-body {
  background: #161616;
  border: 0.1rem solid #00a1e1;
  color: #ffffff;
  display: none;
  font-size: 1.4rem;
  left: 100%;
  line-height: 1.3;
  min-width: 30rem;
  padding: 1.6rem;
  position: absolute;
  text-transform: none;
  top: 0;
  z-index: 1;
}

.tooltip-body a {
  color: #00a1e1;
  text-decoration: underline;
}

.tooltip-body_visible {
  display: block;
}

.tooltip-body_bottomLeft {
  left: 0;
  top: 100%;
  transform: translateX(-100%);
}

.tooltip-trigger {
  display: block;
}

.icon_information {
  border: 0.1rem solid #00a1e1;
  border-radius: 50%;
}

.icon_information > * {
  color: #00a1e1;
  font-size: 1.8rem;
  left: 50%;
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
