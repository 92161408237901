.Main {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.Main .top {
  background-image: url(../../assets/images/header-texture.jpg);
  background-position: 0 0;
  background-repeat: repeat;
  padding: 14rem 0 12rem 0;
}

.Main .top_slim {
  padding: 12rem 0 4rem;
}

.Main .top_alignTop {
  align-items: flex-start;
}

.Main .top_fillScreen {
  flex-grow: 1;
}

.Main .bottom {
  background: #ffffff;
  flex-grow: 2;
  padding: 8rem 0;
}

.Main .bottom_dark {
  background: #161616;
}

.Main .bottom_extraPad {
  padding: 6.4rem  0;
}
