.NavigationDropDownIcon {
  display: inline-block;
  margin-left: auto;
  transition: all .5s cubic-bezier(.25, .46, .45, .94);
  transform-origin: 50% 43%;
  transform: rotate(-180deg) translateZ(0);
}

.NavigationDropDownIcon.open {
  transform: rotate(0);
}

.NavigationDropDownIcon i {
  fill: #00a1e1;
  height: 1.4rem;
  width: 1.4rem;
  display: inline-block;
  position: relative;
}

.NavigationDropDownIcon i > * {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}
