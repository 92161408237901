/* Override Gigya's "width: 450px" style... this looks better on mobile */
.SignInForm .gigya-screen .passport-input {
    max-width: 45rem;
    width: 100%;
}

.SignInForm .gigya-screen input[type='submit'].gigya-input-submit {
    margin: 0 auto;
}

.form-messages_hasMessage {
    display: block;
    margin-bottom: 2.4rem;
}
