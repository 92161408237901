.TableHeadingSort{
  display: flex;
  flex-direction: column;
  padding: 0 .5em;
  cursor: pointer;
}

.TableHeadingSort .sort{
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  height: 0;
  width: 0;
}

.TableHeadingSort .descending{
  border-bottom: 5px solid gray;
  margin-bottom: .25em;
}

.TableHeadingSort .descending.active{
  border-bottom: 5px solid black;
}

.TableHeadingSort .ascending{
  border-top: 5px solid gray;
}

.TableHeadingSort .ascending.active{
  border-top: 5px solid black;
}