/* ---------------------------------------------------------------------
 Set
 --
 A Set of Related Things... usually heading, body, footer
------------------------------------------------------------------------ */

.set {
  display: block;
  width: 100%;
}

.set-bd {
  margin-top: 0.8rem;
  width: 100%;
}

.set-bd_flush {
  margin-top: 0;
}

.set-bd_compact {
  margin-top: 0.4rem);
}

.set-bd_detached {
  margin-top: 1.6rem;
}

.set-bd_separated {
  margin-top: 2.4rem;
}

.set-bd_disengaged {
  margin-top: 3.2rem;
}

.set-bd_mega {
  margin-top: 4rem;
}

.set-bd_giant {
  margin-top: 5.6rem;
}

.set-bd_super {
  margin-top: 6.4rem;
}

.set-bd_isolated {
  margin-top: 8rem;
}

.mix-set-bd_scrollable {
  overflow: auto;
}

.mix-set_narrow {
  max-width: 25rem;
}

.mix-set_centered {
  margin-left: auto;
  margin-right: auto;
}

.mix-set_constrained {
  margin-left: auto;
  margin-right: auto;
  max-width: 26rem;
}

.mix-set_alignLeft,
.mix-set-bd_alignLeft {
  align-items: left;
  text-align: left;
}

.mix-set_alignCenter,
.mix-set-bd_alignCenter {
  text-align: center;
}

.mix-set-bd_borderTop {
  border-top: 0.1rem solid $COLOR_MEDIUM_GRAY;
}

.mix-set-bd_positionLeftMobile {
  left: 0;
  position: absolute;
}

.mix-set_constrained {
  max-width: 37.7rem;
}

.mix-set-bd_positionLeftMobile {
  position: static;
}

.set-bd_isolated_mdLg {
  margin-top: 10rem;
}

.mix-set_slim {
  max-width: 37.7rem;
}

.mix-set_wide {
  max-width: 55.6rem;
}
