.Navigation {
  height: 12rem;
  left: 0;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
}

.Navigation-container {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  padding: 0 1.6rem;
  width: 100%;
}

.Navigation-left {
  margin-right: auto;
  width: 20%;
}

.Navigation-right {
  margin-left: auto;
  width: 20%;
}

.Navigation-center {
  width: 60%;
}

.Navigation-list {
  display: flex;
  justify-content: center;
}

.Navigation-list > * + * {
  margin-left: 3.2rem;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .Navigation-container {
    max-width: 768px;
    padding: 0 3.2rem;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .Navigation-container {
    max-width: 128rem;
    padding: 0 6.4rem;
  }
}
