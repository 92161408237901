/* ---------------------------------------------------------------------
 Badge
 --
 Container for an icon and label badge layout
------------------------------------------------------------------------ */

.badge {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.badge-icon,
.badge-label {
  display: inline-block;
}

.badge-icon {
  background: #ffffff;
  border: 0.1rem solid #161616;
  border-radius: 50%;
  position: relative;
  height: 4.8rem;
  width: 4.8rem;
}

.badge-icon > svg {
  height: 2rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 2rem;
}

.badge-label {
  margin-left: 1.6rem;
}

.mix-badge-icon_colorDefaultBlue {
  border-color: #00a1e1;
  stroke: #00a1e1;
}

.mix-badge-icon_colorDarkGray {
  border-color: #161616;
  stroke: #161616;
}
