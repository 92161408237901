.TableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.Table {
  border-spacing: 0.5rem;
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.Table_spaced {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.Table td,
.Table th {
  font-size: 1.4rem;
  padding: 1.6rem 4rem 1.6rem 0;
}

.Table th {
  text-transform: uppercase;
  vertical-align: top;
}

.Table td {
  vertical-align: top;
}

.Table td a {
  color: #00a1e1;
  text-decoration: none;
  text-transform: uppercase;
}

.Table td div.modify-resource-options {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Table td div.modify-resource-options > * + * {
  margin-left: 0.8rem;
}

.Table-cell_constrained {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Table .th_sortable {
  position: relative;
}

.Table .th_sortable::before {
  border-bottom: 0.5rem solid #000000;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  content: ' ';
  display: block;
  position: absolute;
  right: -1.8rem;
  top: 0.2rem;
}

.Table .th_sortable::after {
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid #000000;
  content: ' ';
  display: block;
  position: absolute;
  right: -1.8rem;
  top: 1rem;
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .Table td div.modify-resource-options > * + * {
    margin-left: 0;
  }
}
