/* Override Gigya's "width: 450px" style... this looks better on mobile */
.SignInForm .gigya-screen .passport-input {
    max-width: 45rem;
    width: 100%;
}

.SignInForm .gigya-screen input[type='submit'].gigya-input-submit {
    margin: 0 auto;
}

.set-hd {
    color: #fff;
    line-height: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    vertical-align: baseline;
}

.set-bd_super {
    margin-top: 6.4rem;
}

.set-bd {
    margin-top: 0.8rem;
    width: 100%;
}

.hidden {
    display: none;
}

.set {
    color: #fff;
    line-height: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    vertical-align: baseline;
    display: block;
    width: 100%;
}

.set_alignCenter {
    text-align: center;
}

.btn_fixedWidth {
    width: 100%;
}

.btn {
    background: transparent;
    border: 0.1rem solid #00a1e1;
    color: #00a1e1;
    font-family: tk-klavika-web-n3, sans-serif;
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
    line-height: 1.2;
    height: auto;
    outline: none;
    padding: 1.8rem 2.4rem 1.5rem;
    text-align: center;
    text-transform: uppercase;
    width: auto;
    -webkit-appearance: none;
    z-index: 2;
}

input[type='checkbox'] {
    margin-top: 1.5rem;
}

label {
    color: #fff;
}
