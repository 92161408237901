/* ---------------------------------------------------------------------
 Element Styles
------------------------------------------------------------------------ */

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    overflow-y: scroll;
}

body {
    background: #161616;
    font-family: 'klavika-web', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
}

html,
body,
#app,
.site {
    height: 100%;
    width: 100%;
}

.site {
    display: flex;
    flex-direction: column;
}

.site-main {
    display: flex;
    flex: 1 0 auto;
}

.site-footer {
    flex: none;
}

.container {
    margin: 0 auto;
    padding: 0 1.6rem;
    width: 100%;
}

button {
    appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    font-family: 'klavika-web', sans-serif;
    font-size: 1.6rem;
    padding: 0;
}

.icon {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
}

/* TABLET STYLES */

@media only screen and (min-width: 768px) {
    .container {
        max-width: 768px;
        padding: 0 1.6rem;
    }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
    .container {
        max-width: 128rem;
        padding: 0 6.4rem;
    }
}

.btn:disabled {
    border: 0.1rem solid #666;
    color: #666;
}

.form-messages_hasMessage a {
    color: #fff;
}

.filter-label-text {
    color: black;
}

.filter-label-content {
    color: black;
}
