.LocalePicker-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: auto;
}

.LocalePicker-heading {
  color: #161616;
  font-weight: 300;
  margin: 3.2rem auto 0 auto;
  text-transform: uppercase;
}

.LocalePicker-save {
  margin-top: 3.2rem;
}

.LocalePicker-cancel {
  margin-top: 2.4rem;
  text-decoration: none;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .LocalePicker-heading {
    margin: 0 auto 7rem;
  }

  .LocalePicker-save {
    margin-top: 7.2rem;
  }

  .LocalePicker-cancel {
    margin-top: 3.2rem;
  }
}
