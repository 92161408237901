.Horizontal-Bar-Chart.Axis path{
  fill: none;
  stroke: none;
}

.Horizontal-Bar-Chart.Axis-Left .tick line {
  stroke: none;
}

.Horizontal-Bar-Chart.Axis-Left text {
  fill: none;
}

.Horizontal-Bar-Chart.Axis-Bottom .tick line {
  stroke: #D3D3D3;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}