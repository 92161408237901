.Logo {
  display: inline-block;
  height: 2.4rem;
  width: 6.2rem;
}

.Logo_block {
  display: block;
}

.Logo_sm {
  height: 2rem;
  width: 5rem;
}

.Logo > svg {
  fill: #00a1e1;
  height: 100%;
  width: 100%;
}

.Logo_colorDarkGray > svg {
  fill: #8b8b8b;
}
