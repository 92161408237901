.NavigationLink{
  display: block;
  color: #00a1e1;
  font-weight: 300;
  padding-bottom: .4rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  top: 0.2rem;
}

.NavigationLink.active {
  color: #ffffff;
  border-bottom: 1px solid #449FDB;
}
