.form-container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin: 0 auto;
  max-width: 45rem;
  width: 100%;
}

.form-container_left {
  margin-left: 0;
}

.form-row + .form-row {
  margin-top: 2rem;
}

.form-row_lgTop {
  margin-top: 6.4rem;
}

.form-control {
  margin-top: .5em;
  text-align: left;
}

.form-control .label {
  color: #9D9D9D;
  font-size: 1.6rem;
  letter-spacing: 0.09rem;
  text-transform: uppercase;
}

.form-control .input {
  background: transparent;
  border: 0;
  border-bottom: .1rem solid  #9D9D9D;
  color: #ffffff;
  font-size: 3.6rem;
  font-weight: 300;
  width: 100%;
}

.form-control .input:focus {
  border-color: #00a1e1;
  outline: 0;
}

.form-control .input_area {
  border: .1rem solid  #9D9D9D;
  font-size: 1.8rem;
  min-height: 30rem;
  padding: 0.8rem;
}

.form-control_light .label,
.form-control_light .input {
  color: #161616;
}

.form-control_light .input {
  border-color: #161616;
}

.link-container {
  margin-top: 2rem;
  text-align: left;
}

.link-container_alignCenter {
  text-align: center;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.form-control .error {
  font-size: 1.4rem;
}

.form-control .label_error,
.form-control .error {
  color: #fb541a;
}

.form-control .input_error,
.form-control .input_error:focus {
  border-color: #fb541a;
}

.form-control .error-item + .error-item {
  margin-top: 1.6rem;
}
