.metric {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  justify-content: space-between;
  padding: 2rem 0 0;
  position: relative;
  text-transform: uppercase;
}

.metric-type {
  font-weight: 400;
  color: #9d9d9d;
  font-size: 1.8rem;
  line-height: 2;
}

.metric-name {
  font-weight: 400;
  font-size: 3.2rem;
  color: white;
  letter-spacing: 0.2rem;
}

.metric-name > * {
  display: inline-block;
  margin-right: 1rem;
  vertical-align: middle;
}

.metric-quantity {
  font-weight: 300;
  font-size: 7.2rem;
  color: white;
  line-height: 1.75;
}

.metric .tooltip {
  right: 0;
  top: 1.6rem;
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .metric-quantity {
    font-size: 10rem;
  }
}
