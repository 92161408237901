.ResourceCheckboxInput {
  margin: 1em;
  width:100%;
}

.ResourceCheckboxInput.state,
.ResourceCheckboxInput.zip_code{
  width: 41%;
}

.ResourceCheckboxInput .form-control {
  text-align: left;
  margin-top: .5em;
  font-weight: 400;
}

.ResourceCheckboxInput .form-control .label,
.ResourceCheckboxInput .form-control .input,
.ResourceCheckboxInput .link-container {
  font-size: 1em;
}

.ResourceCheckboxInput .form-control .label {
  color: #444d50;
  text-transform: uppercase;
}

.ResourceCheckboxInput .input-control {
  border: .1rem solid  #9D9D9D;
}

.ResourceCheckboxInput .form-control .input {
  background: transparent;
  border: none;
  font-weight: 400;
  font-size: .9em;
  color: #444d50;
  padding: .7em;
  width: 100%;
}

.ResourceCheckboxInput .form-control .input:focus {
  outline:none;
}

.ResourceCheckboxInput .link-container {
  margin-top: .2em;
  text-align: left;
}

.ResourceCheckboxInput .submit-button-container {
  display: flex;
  justify-content: center;
}
