.control-group {
  display: inline-block;
  background: #fff;
  text-align: left;
  font-weight: 300;
  font-size: .8em;
}

.control {
  display: block;
  position: relative;
  padding-left: 2.3em;
  margin-top: 15px;
  cursor: pointer;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #fff;
}

.control input:checked ~ .control__indicator {
  background: #fff;
}

.control input:checked:focus ~ .control__indicator {
  background: #fff;
}

.control input:checked ~ .control__indicator:after {
  display: block;
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}