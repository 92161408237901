.Landing {
  text-align: center;
}

.cta-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2em;
}
