.Analytics {
  display: flex;
  flex-direction: column;
}

.Analytics .product-group-container {
  display: flex;
  flex-direction: column;
  margin-top: 7.2rem;
  width: 100%;
}

.Analytics h2.product-name {
  font-weight: 300;
  font-size: 2.4rem;
  text-transform: uppercase;
}

.chart-group-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3.6rem;
  width: 100%;
}

.chart-group-container.full.left .chart-group {
  margin: 0;
}

.chart-group-container.full .chart-group {
  margin: 0 auto;
}

.chart-group {
  width: 100%;
}

.chart-group + .chart-group {
  margin-top: 3.6rem;
}

.chart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chart {
  font-weight: 400;
  position: relative;
  width: 100%;
  padding-bottom: 60%;
}

.chart_horizontalBar {
  padding-bottom: 50rem;
}

.donut-chart {
  max-height: 30rem;
}

.chart svg {
  height: 100%;
  left: 50%;
  overflow: visible;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.chart-label {
  color: #444d50;
  font-weight: 400;
  font-size: 1.6rem;
  font-weight:bold;
  margin-bottom: 4rem;
  width: 100%;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .chart-group-container {
    flex-direction: row;
  }

  .chart-group {
    width: 40%;
  }

  .chart-group-container.full.left .chart-group {
    width: 80%;
  }

  .chart-group-container.full .chart-group {
    width: 80%;
  }

  .chart-group-container.full .chart,
  .chart_full {
    padding-bottom: 40%;
  }
}
