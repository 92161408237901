.analytics-filters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;;
  text-transform: uppercase;
  font-weight: 400;
}

.search-filters-container,
.page-filters-container {
  display: flex;
  flex-direction: column;
}

.page-filters-container {
  flex: 1;
}

.search-filters-container {
  flex-grow: 2;
  margin-top: 3.2rem;
}

.page-filter-button-group {
  display: flex;
}

.filter-label {
  padding-bottom: 1.5rem;
  font-size: 1.8rem;
}

.filter-item {
  display: block;
  border: 0.1rem solid #00a1e1;
  color: #161616;
  letter-spacing: 0.09rem;
  line-height: 1.2;
  padding: 1rem 1.5rem;
  font-size: .9em;
  text-align: center;
  text-decoration: none;
}

.filter-item.active {
  color: white;
  background: #449FDB;
}

.filter-item:visited {
  color: #161616;
}

.filter-item.active:visited {
  color: #ffffff;
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .analytics-filters {
    flex-direction: row;
  }

  .search-filters-container {
    margin-left: 6em;
    margin-top: 0;
  }
}
