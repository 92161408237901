.Sessions a.export-list-link {
  display: block;
  font-weight: 400;
  font-weight: bold;
  color: #449FDB;
  text-decoration: none;
  text-transform: uppercase;
}

.sessions-table-header {
  margin-bottom: 3.2rem;
}

.Sessions .export-list-link-container {
  margin-top: 1.6rem;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .Sessions .export-list-link-container {
    margin-top: 0;
  }

  .Sessions .sessions-table-footer {
    display: flex;
    justify-content: space-between;
    margin-top: .55em;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .sessions-table-container {
    padding-left: 2.4rem;
  }
}
