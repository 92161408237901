/* $COLOR_DEFAULT_BLUE: #00a1e1;
$COLOR_DEFAULT_GREEN: #80bb41;
$COLOR_DEFAULT_ORANGE: #fb541a; */

.field_hasError .field-label {
    color: #fb541a;
}

.field_hasError .field-input {
    border-color: #fb541a;
    color: #fb541a;
}

.field_hasError .field-error {
    visibility: visible;
}

/* .field-error {
    font-family: tk-klavika-web-n4, sans-serif;
    font-size: 1.3rem;
    position: absolute;
    top: 100%;
    -ms-transform: translateY(0.8rem);
    transform: translateY(0.8rem);
    visibility: hidden;
} */

.field-error {
    line-height: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    vertical-align: baseline;
    font-family: tk-klavika-web-n4, sans-serif;
    font-size: 1.3rem;
    position: absolute;
    top: 100%;
    transform: translateY(0.8rem);
    color: #fb541a;
    visibility: hidden;
}

.field {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
}

.field-label {
    line-height: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    vertical-align: baseline;
    color: #9d9d9d;
    display: block;
    font-family: tk-klavika-web-n3, sans-serif;
    font-size: 1.6rem;
    letter-spacing: 0.09rem;
    margin-bottom: 0.8rem;
    text-transform: uppercase;
    width: 100%;
    opacity: 1;
    transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.field-input {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: none;
    border-bottom: 0.1rem solid #9d9d9d;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    display: block;
    font-family: tk-klavika-web-n3, sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    transition: border-color 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    font-size: 3.6rem;
}
