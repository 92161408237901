/* ----------------------
 FOOTER STYLES
------------------------- */

.footer {
  background: #292929;
  display: block;
  flex-wrap: nowrap;
}

.footer-inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2.4rem 1.6rem;
}

.footerLogo {
  text-align: center;
  width: auto;
}

.footerInfo {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 2;
  justify-content: flex-end;
  margin-top: 2.4rem;
  width: auto;
}

.footerInfo > * + * {
  margin-top: 1.6rem;
}

.footerLink {
  color: #8b8b8b;
  font-size: 1.4rem;
  position: relative;
  text-decoration: none;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .footer-inner {
    flex-direction: row;
    max-width: 768px;
    padding: 2.4rem 3.2rem;
  }

  .footerInfo {
    flex-direction: row;
    margin-top: 0;
  }

  .footerLink {
    padding-right: 2.4rem;
  }

  .footerInfo > * + * {
    margin-top: 0;
  }

  .footerInfo > *:not(:first-child) {
    margin-left: 2rem;
  }

  .footerLink::after {
    border-right: 0.1rem solid #8b8b8b;
    content: '';
    display: inline-block;
    height: 1.6rem;
    width: 0.1rem;
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* DESKTOP STYLES */
@media only screen and (min-width: 1024px) {
  .footer-inner {
    max-width: 128rem;
    padding: 2.4rem 6.4rem;
  }
}
