.AddTransactionForm-container {
  width: 100%;
}

.AddTransactionForm-container .form-heading {
  color: #161616;
  font-weight: 300;
  font-size: 2.75rem;
  margin: 0 auto 7rem;
  text-transform: uppercase;
}

.ProgressBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  letter-spacing: .1rem;
  margin: 5rem auto;
  width: 100%;
}

.parent-label {
  margin: 1rem 0;
}

.parent-label button {
  text-align: left;
}

.ProgressBarStepLabel {
  color: #9D9D9D;
  font-weight: 300;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.ProgressBarStepLabel_active {
  color: #161616;
  font-weight: 400;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.ProgressBarStepLabel_default {
  color: #161616;
  font-weight: 300;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.ProgressBarStepLabel_previous {
  color: #9D9D9D;
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.subtitle {
  display: none;
}

.subtitle_previous {
  display: block;
  color: #00a1e1;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: .1rem;
  padding-top: 1rem;
  width: 100%;
}

.AddTransactionForm {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5.6rem;
}

.AddTransactionForm .form-step {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.AddTransactionForm .form-step .Button {
  color: #161616;
  font-size: 1.7rem;
  margin: 0 0 2rem;
}

.confirm-transaction {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 5rem 0;
}

.confirm-transaction_summary {
  align-items: flex-end;
  display: flex;
  font-size: 1.8rem;
  flex-direction: column;
  margin: 0 0 15rem;
}

.confirm-transaction_right {
  display: flex;
  flex-direction: column;
}

.confirm-transaction_left {
  display: flex;
  flex-direction: column;
}

.confirm-transaction_labels {
  color: #9D9D9D;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  justify-content: space-around;
  letter-spacing: .1rem;
  text-transform: uppercase;
  width: 80%;
}

.confirm-transaction_labels li {
  display: flex;
  flex-direction: column;
}

.confirm-transaction_labels .subtitles {
  color: #161616;
  font-size: 2.5rem;
  font-weight: 300;
  margin: 1.2rem 0;
}

.confirm-transaction .confirm-transaction_right .Button {
  width: 100%;
}

.confirm-transaction_email {
  font-weight: 800;
}

.transaction-completed {
  font-size: 2.5rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
  width: 100%;
}

.transaction-completed_confirmation {
  display: flex;
}

.transaction-completed_txt {
  margin-top: 2.5rem;
}

.transaction-completed_icon {
  background-image: url(../../assets/images/icons-check-mark.svg);
  height: 7rem;
  background-repeat: no-repeat;
  width: 9rem;
}

.transaction-completed_add-another {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5rem 0;
}

.transaction-completed .transaction-completed_add-another .Button {
  margin: 8rem 0 4rem;
}

.back-to-session_link {
  color: #00a1e1;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
}

.modal-content {
  align-items: baseline !important;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
  .AddTransactionForm-container {
    display: flex;
    flex-direction: column;
  }

  .ProgressBar {
    flex-direction: row;
  }

  .parent-label {
    margin: 0 1rem;
    text-align: center;
    white-space: normal;
    width: 12rem;
  }

  .parent-label button {
    text-align: center;
  }

  .AddTransactionForm .form-step .Button {
    width: 30%;
  }

  .confirm-transaction {
    width: 90%;
  }

  .confirm-transaction_left {
    width: 65%;
  }
}
