.PageLink {
  color: #00a1e1;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
}

.PageLink_normal {
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: none;
}

.PageLink_heavy {
  font-size: 1.8rem;
  font-weight: 600;
}
