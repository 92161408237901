.RadioButtonGroup .control--radio .control__indicator {
  border-radius: 50%;
}

.RadioButtonGroup .control--radio .control__indicator:after {
  background: #0097DD;
  border-radius: 50%;
  height: 13px;
  left: 50%;
  width: 13px;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
