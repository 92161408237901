.Button {
    background: transparent;
    border: 0.1rem solid #00a1e1;
    color: #00a1e1;
    cursor: pointer;
    display: inline-block;
    font-weight: 300;
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
    line-height: 1.2;
    height: auto;
    outline: none;
    padding: 1.8rem 2.4rem 1.5rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    min-width: 150px;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.Button:hover,
.Button:focus {
    background: #00a1e1;
    color: #ffffff;
}

.Button:disabled {
    border: 0.1rem solid #666;
    color: #666;
    background: transparent;
}

.Button_light {
    color: #161616;
}

/* TABLET STYLES */
@media only screen and (min-width: 768px) {
    .Button {
        width: auto;
    }

    .Button_md-wide {
        padding: 2rem 9.4rem 1.8rem;
    }
}
