.EmployeesTable td div.modify-resource-options {
  display: flex;
  justify-content: space-between;
  color: #449FDB;
  text-transform: none;
}

.EmployeesTable td div.shops-list-container {
  display: flex;
  flex-direction: column;
}

.EmployeesTable td div.shops-list-container div.shop {
  margin-bottom: .25em;
}

.EmployeesTable td div.modify-resource-options div {
  padding: 0 .1em;
}
