
.Axis path {
  stroke: none;
}

.XAxis-Labels g.tick text,
.YAxis-Labels g.tick text
{
  font-weight: 300;
  font-size: 1.3em;
}

.XAxis-Labels g.tick line,
.YAxis-Labels g.tick line{
  stroke: none;
}

.XAxis-Ticks g.tick text,
.YAxis-Ticks g.tick text {
  fill: none;
}

.YAxis-Ticks g.tick line,
.XAxis-Ticks g.tick line
{
  stroke: #c1c1c1;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}