.Vertical-Bar-Chart.Axis path {
  stroke: none;
}

.Vertical-Bar-Chart.Axis text {
  font-weight: 300;
  font-size: 1.3em;
}

.Vertical-Bar-Chart.Axis-Left .tick line {
  stroke: none;
}

.Vertical-Bar-Chart.Axis-Left .tick line {
  stroke: #D3D3D3;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
